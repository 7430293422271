<template>
  <div>
    <v-card class="desktop_card">
        <v-card-title>
        <label class="--v-primary-base text-left text-capitalize mr-4">{{ $t(`label.myProfile`) }}</label>
      </v-card-title>
      <v-card class="profile-detail-card" elevation="0">
      <v-row no-gutters align="end" class="px-8 pa-4">
        <v-col lg="4" md="5">
          <v-row>
            <v-col cols="6">
              Current Level Name
            </v-col>
            <v-col cols="6">
              {{ vipProgress.current_level_name }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              Current Deposit Progress
            </v-col>
            <v-col cols="6">
              {{ vipProgress.current_deposit_progress }} / {{ vipProgress.required_deposit_amount }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              Current Rollover Progress
            </v-col>
            <v-col cols="6">
              {{ vipProgress.current_rollover_progress }} / {{ vipProgress.required_account_rollover }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="4" lg="3">
          <app-button class="dialog-button" :disabled="!this.isApplicable" :action="this.applyVipBonus" title="Apply Special VIP Bonus"></app-button>
        </v-col>
        <v-col md="4" lg="3">
          <app-button class="dialog-button" :disabled="!this.vipProgress.has_pending_claim" :action="this.claimVipProgress" title="Claim VIP Progress"></app-button>

        </v-col>
      </v-row>
      <v-row>
        <!-- <table>
          <tr>
            <td>Level</td>
            <td v-for="reward in vipRewardsSummaryData">
              <img class="icon-size" :src="getDisplayIcon(reward)" />
            </td>
          </tr>
          <tr>
            <td>Level</td>
            <td v-for="reward in vipRewardsSummaryData">{{ reward.level }}</td>
          </tr>
          <tr>
            <td>Rewards</td>
            <td v-for="reward in vipRewardsSummaryData">{{ reward.text }}</td>
          </tr>
          <tr>
            <td>Requirements</td>
            <td v-for="reward in vipRewardsSummaryData">{{ reward.requirements }}</td>
          </tr>
        </table> -->
        <v-col cols=12 class="text-center mb-6" v-if="pagination.totalPage > 1">
         <v-pagination class="game_pagination" v-model="pagination.currentPage" :length="pagination.totalPage" @input="changePage"
          :next-icon="'arrow_forward_ios'"
          :prev-icon="'arrow_back_ios'"
          :total-visible="pagination.paginationPageLimit"></v-pagination>
        </v-col>
      </v-row>
    </v-card>
  </v-card>
  </div>
</template>

<script>
import {
  VIP_BONUS_APPLY,
  VIP_BONUS_CHECK,
  VIP_BONUS_RESET,
  VIP_CLAIM_REWARD, VIP_RESET_CLAIM, VIP_REWARDS_SUMMARY,
  VOUCHER_BONUS_CHECK
} from "../../store/bonus.module";
import {MEMBER_GET_VIP_PROGRESS} from "../../store/member.module";
import {VipClaimType} from "../../constants/enums";
import {ROUTE_NAME} from "../../constants/route.constants";

export default {
  name: "vipPage",
  data: () => ({
    pagination: {
        currentPage: 1,
        totalPage: 1,
        paginationPageLimit: 10
      },
    currrentVipSummary: [],
    searchCriteria: {
        pageNumber: 1,
        pageSize: 20,
        mobilePageSize: 20
      }
  }),
  computed: {
    isApplicable() {
      return this.$store.state.bonus.vipBonusCheckResult.data.available
    },
    applyComplete() {
      return this.$store.state.bonus.vipBonusApplyResult.complete
    },
    vipProgress() {
      return this.$store.state.member.vipProgress
    },
    vipClaimComplete() {
      return this.$store.state.bonus.vipProgressClaimResult.complete
    },
    vipRewardsSummaryData() {
      if (this.$store.state.bonus.vipRewardsSummary.length > 10)
        return this.$store.state.bonus.vipRewardsSummary.slice(0, 10)
      return this.$store.state.bonus.vipRewardsSummary
    },
    vipClaimType() {
      switch (this.vipProgress.member_progress_type) {
        case VipClaimType.BOTH:
          return "Rollover and Deposit"
        case VipClaimType.DEPOSIT_ONLY:
          return "Deposit Only"
        case VipClaimType.ROLLOVER_ONLY:
          return "Rollover Only"
      }
    }
  },
  watch: {
    applySuccess() {
    },
    applyComplete() {
      if (this.$store.state.bonus.vipBonusApplyResult.complete) {
        window.alert("VIP Bonus apply status: " + this.$store.state.bonus.vipBonusApplyResult.success)
        this.$store.dispatch(`${VIP_BONUS_RESET}`)
        this.getVipApplicability()
      }
    },
    vipClaimComplete() {
      if (this.$store.state.bonus.vipProgressClaimResult.complete) {
        this.showVipClaimProgress(this.$store.state.bonus.vipProgressClaimResult)
      }
    },
    vipRewardsSummaryData() {
       this.renderPage()
      // this.changePage(1)
    }
  },
  created() {
    this.getVipApplicability()
    this.getVipProgress()
    this.getVipRewardsSummary()
    
  },
  methods: {
    getVipApplicability() {
      this.$store.dispatch(`${VIP_BONUS_CHECK}`)
    },
    getVipProgress() {
      this.$store.dispatch(MEMBER_GET_VIP_PROGRESS)
    },
    getVipRewardsSummary() {
      this.$store.dispatch(VIP_REWARDS_SUMMARY)
    },
    renderPage() {
      if(this.$vuetify.breakpoint.xsOnly){
        this.pagination.totalPage = Math.ceil(this.vipRewardsSummaryData.length / this.searchCriteria.mobilePageSize)
      } else {
        this.pagination.totalPage = Math.ceil(this.vipRewardsSummaryData.length / this.searchCriteria.pageSize)
      }
    },
    changePage() {
      this.currrentVipSummary =  this.vipRewardsSummaryData.slice((this.searchCriteria.pageNumber - 1) * this.searchCriteria.mobilePageSize, this.searchCriteria.pageNumber * this.searchCriteria.mobilePageSize)
      this.currrentVipSummary =  this.vipRewardsSummaryData.slice((this.searchCriteria.pageNumber - 1) * this.searchCriteria.pageSize, this.searchCriteria.pageNumber * this.searchCriteria.pageSize)
    },
    applyVipBonus() {
      const selectedBonusId = this.$store.state.bonus.vipBonusCheckResult.data.bonus_id
      this.$router.push({
        name: ROUTE_NAME.DEPOSIT,
        params: {
          bonusId: selectedBonusId,
          lang: this.$route.params.lang
        }
      })
    },
    getDisplayIcon(reward){
      switch (reward.reward_type) {
        case 'COINS':
          return this.getCoinPath()
        case 'GIFT':
          return reward.reward_gift && reward.reward_gift.imgPath
        case 'BONUS':
          return this.getGiftPath()
      }
    },
    // hard code asset path
    getCoinPath() {
      return 'https://jiliwin.9terawolf.com/cms/babuVip/vip-reward-icon.png'
    },
    getGiftPath() {
      return 'https://jiliwin.9terawolf.com/cms/babuVip/vip-reward-gift.png'
    },
    claimVipProgress() {
      this.$store.dispatch(VIP_CLAIM_REWARD)
    },
    showVipClaimProgress(result) {
      if (result.success) {
        window.alert("Successfully claimed VIP progress")
      } else {
        window.alert("VIP Progress claim failure " + result.message)
      }
      this.$store.dispatch(VIP_RESET_CLAIM)
      this.getVipProgress()
    }
  }
}
</script>

<style lang="scss">
.icon-size {
  height: 1em;
}
 .game_pagination .v-pagination {
    li {
      border: none !important;
      button {
        border: none !important;
        font-size: 14px;
      }
    }
  }
</style>
